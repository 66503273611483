<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#7B7E8C"/>
    <path d="M15.7101 15.9298C15.5801 15.9298 15.4501 15.8998 15.3301 15.8198L12.2301 13.9698C11.4601 13.5098 10.8901 12.4998 10.8901 11.6098V7.50977C10.8901 7.09977 11.2301 6.75977 11.6401 6.75977C12.0501 6.75977 12.3901 7.09977 12.3901 7.50977V11.6098C12.3901 11.9698 12.6901 12.4998 13.0001 12.6798L16.1001 14.5298C16.4601 14.7398 16.5701 15.1998 16.3601 15.5598C16.2101 15.7998 15.9601 15.9298 15.7101 15.9298Z" fill="#7B7E8C"/>
  </svg>
</template>

<script>
export default {
  name: "ClockIcon",
  props: {
    width: {
      type: Number,
      default: 32,
    },
    height: {
      type: Number,
      default: 32,
    },
  }
}
</script>