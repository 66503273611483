<template>
	<div class="container-custom container-detail">
		<Modal
			v-if="isModalPresensi"
			@close="() => (isModalPresensi = false)"
			width="50%">
			<div style="padding: 30px">
				<h2 class="modal-title" style="text-align: left">
					Tentang Presensi Kelas
				</h2>
				<div class="modal-body" style="text-align: left">
					<ol>
						<li>
							Peserta wajib mengisi Presensi Kelas Awal/Hadir &
							Presensi Akhir/Keluar sebagai
							<b>Data Kehadiran Peserta</b> tiap sesi.
						</li>
						<li>
							Presensi Awal/Hadir akan aktif dari
							<b>60 menit sebelum dan setelah event berlangsung</b
							>.
						</li>
						<li>
							Presensi Akhir/Keluar akan aktif
							<b>saat waktu kelas berakhir</b> hingga
							<b>60 menit setelah waktu kelas berakhir</b>.
						</li>
					</ol>
				</div>
			</div>
		</Modal>
		<Modal
			v-if="isModalActivity"
			@close="() => (isModalActivity = false)"
			width="50%">
			<div style="padding: 30px">
				<h2 class="modal-title" style="text-align: left">
					Tentang Aktivitas Kelas
				</h2>
				<div class="modal-body" style="text-align: left">
					<ol>
						<li>
							Peserta kelas event mini bootcamp dapat bergabung
							dalam sesi webinar melalui tombol
							<b>Gabung Kelas</b>.
						</li>
						<li>
							Tombol Gabung Kelas akan aktif apabila peserta
							<b>telah melakukan Presensi Awal/Hadir</b>.
						</li>
						<li>
							Pastikan <b>memiliki akun Zoom/Google Meet</b> untuk
							mengikuti pelatihan berbasis Daring-Webinar.
						</li>
					</ol>
				</div>
			</div>
		</Modal>
		<div class="list-scrumb" style="margin-top: -30px">
			<div
				v-for="(item, idx) in items"
				:key="idx"
				class="list-scrumb ml-1 cursor-pointer"
				@click="toGo(item.href)">
				<span
					:class="`scrumb-text ${
						idx === items.length - 1 ? 'inactive-scrumb' : ''
					} `"
					>{{ item.text }}</span
				>
				<ArrowIcon
					v-if="idx < items.length - 1"
					strokeColor="#575966"
					:width="16"
					:height="16"
					direct="right" />
			</div>
		</div>
		<div class="d-flex" style="margin-top: 40px; margin-left: 10px">
			<div class="d-flex">
				<div style="display: flex; justify-content: center">
					<img
						:src="
							schedule.image_url
								? schedule.image_url
								: '/img/logo-hc.png'
						"
						width="80px"
						height="80px"
						style="border-radius: 16px" />
				</div>
				<div style="margin-left: 32px">
					<div class="container-label-top">
						<div
							class="label-top"
							style="background-color: #f1f6ff">
							{{
								categories.find(
									(item) => item.value === schedule.category,
								).name
							}}
						</div>
						<div
							class="label-top"
							style="background-color: #faeceb">
							{{ schedule.learning_method }}
						</div>
					</div>
					<span class="title-card" id="font-poppins">{{
						schedule.name
					}}</span>
					<div style="display: flex; gap: 32px; margin-top: 16px">
						<div class="box-info-card">
							<LevelIcon :width="24" :height="24" />
							<span>{{
								$utils.formatLevel(schedule.level)
							}}</span>
						</div>
						<div class="box-info-card">
							<CalendarIcon :width="24" :height="24" />
							<span
								>{{ schedule.dates }},
								{{ schedule.hours }}</span
							>
						</div>
						<div class="box-info-card">
							<MediaIcon :width="24" :height="24" />
							<span>{{ schedule.media }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="ml-auto">
				<button class="session-survey">
					<MessageIcon :width="24" :height="24" />
					<span>Isi Survey & Ulasan</span>
				</button>
			</div>
		</div>
		<div
			class="container-info"
			style="margin-top: 40px"
			v-if="schedule.status">
			<div class="container-presensi-heading">
				<div class="presensi-heading">
					<h2>Presensi Kelas</h2>
					<div class="cursor-pointer" @click="isModalPresensi = true">
						<InformationIcon :width="20" :height="20" />
					</div>
				</div>
				<span
					>Konfirmasi kehadiran peserta pada saat pelatihan dimulai
					dan selesai per sesi kelas.</span
				>
			</div>
			<div class="container-box-presensi">
				<div class="box-presensi">
					<p>Presensi Awal/Hadir</p>
					<div class="action-presensi">
						<div class="time-presensi">
							{{ '--:--' }}
						</div>
						<button
							@click="addAttendance()"
							class="btn-confirmation-presensi">
							KONFIRMASI HADIR
						</button>
					</div>
				</div>
				<div class="box-presensi">
					<p>Presensi Akhir/Keluar</p>
					<div class="action-presensi">
						<div class="time-presensi">
							{{ '--:--' }}
						</div>
						<button disabled class="btn-inactive-presensi">
							<TimerThinIcon :width="24" :height="24" />
							<span>Presensi Belum Aktif</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div
			class="container-info"
			style="
				margin-top: 16px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			">
			<div class="container-activity-heading">
				<div class="activity-heading">
					<h2>Aktivitas Kelas</h2>
					<div class="cursor-pointer" @click="isModalActivity = true">
						<InformationIcon :width="20" :height="20" />
					</div>
				</div>
				<span
					>Gabung aktivitas kelas pelatihan! Pastikan memiliki akun
					aplikasi Zoom/Google Meet untuk mengikuti kelas ini.</span
				>
			</div>
			<button
				class="btn-activity"
				@click="goToPage(schedule.learning_link)">
				<span>GABUNG KELAS</span>
			</button>
		</div>
		<div
			class="container-info"
			style="
				margin-top: 16px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			">
			<div class="container-materi-heading">
				<div class="materi-heading">
					<h2>Materi Kelas</h2>
				</div>
				<span
					>Gabung aktivitas kelas pelatihan! Pastikan memiliki akun
					aplikasi Zoom/Google Meet untuk mengikuti kelas ini.</span
				>
			</div>
			<button
				class="btn-materi"
				@click="goToPage(schedule.material_link)">
				<span>MATERI PELATIHAN</span>
			</button>
		</div>
		<div
			class="container-info"
			style="margin-top: 16px"
			v-if="schedule.status">
			<div class="container-evaluation-heading">
				<div class="evaluation-heading">
					<h2>Evaluasi Kelas</h2>
				</div>
				<span
					>Kerjakan tes evaluasi pelatihan untuk mengukur
					kemampuanmu.</span
				>
			</div>
			<div class="container-box-evaluation">
				<div class="box-evaluation">
					<div class="evaluation-test">
						<p>Pre-Test</p>
						<span
							>Tes untuk mengukur kemampuan dasar sebelum
							mengikuti event
							{{
								categories.find(
									(item) => item.value === schedule.category,
								).name
							}}.</span
						>
					</div>
					<button
						class="btn-test"
						@click="goToCurrentPage('Pre Test', 1)">
						<span>KERJAKAN</span>
					</button>
				</div>
				<div class="box-evaluation">
					<div class="evaluation-test">
						<p>Post-Test</p>
						<span
							>Tes untuk mengukur kemampuan dasar sebelum
							mengikuti event
							{{
								categories.find(
									(item) => item.value === schedule.category,
								).name
							}}.</span
						>
					</div>
					<button
						disabled
						class="btn-test-inactive"
						@click="goToCurrentPage('Post Test', 1)">
						<TimerThinIcon :width="18" :height="18" />
						<span>BELUM AKTIF</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import Modal from '../../Modal.vue';
import ArrowIcon from '../../../assets/icons/Arrow.vue';
import LevelIcon from '../../../assets/icons/Level.vue';
import CalendarIcon from '../../../assets/icons/Calendar.vue';
import MediaIcon from '../../../assets/icons/Media.vue';
import MessageIcon from '../../../assets/icons/Message.vue';
import InformationIcon from '../../../assets/icons/InformationCircle.vue';
import TimerThinIcon from '../../../assets/icons/TimerThin.vue';
import eventsDummy from '../../../assets/datadummy/events.json';

export default {
	name: 'DetailClass',
	components: {
		ArrowIcon,
		LevelIcon,
		CalendarIcon,
		MediaIcon,
		MessageIcon,
		Modal,
		InformationIcon,
		TimerThinIcon,
	},
	data() {
		return {
			param: {
				class_id: this.$route.params.id,
				session: this.$route.query.session,
			},
			isModalPresensi: false,
			isModalActivity: false,
			isAssesmentHide: true,
			isMateriSilabus: true,
			doneAbsen: false,
			isAbsen: false,
			items: [
				{
					text: 'Aktivitas',
					disabled: false,
					href: '/active-class/event',
				},
				{
					text: 'Kelasku',
					disabled: false,
					href: '/active-class/event',
				},
				{
					text: 'Detail Kelas',
					disabled: true,
				},
			],
			categories: [
				{ name: 'Bootcamp', value: 5 },
				{ name: 'Public Training', value: 1 },
				{ name: 'Mini Bootcamp', value: 2 },
				{ name: 'Corporate Training', value: 3 },
				{ name: 'Kartu Prakerja', value: 4 },
			],
			syllabuses: [
				{
					id: 1,
					session: 'Pertemuan 1',
					description: '<ul><li>Dasar dalam Desain</li></ul>',
				},
				{
					id: 2,
					session: 'Pertemuan 2',
					description: '<ul><li>Dasar dalam Desain</li></ul>',
				},
			],
			attendance: {},
			schedule: {},
			tpmData: {},
			assesment: {},
			userID: this.$decode_token['id'],
			isAssesmentAvailable: false,
			date: moment(new Date()).format('ddd, DD MMM YYYY'),
		};
	},
	methods: {
		async getClass() {
			this.schedule = eventsDummy.find(
				(item) => item.class_id == this.param.class_id,
			);
			this.tpmData = this.schedule.find(
				(item) => item.iteration == this.param.session,
			);
			//   await this.$http
			//     .get(
			//       "/v1/user-schedule/detail?user_id=" +
			//         this.userID +
			//         "&class_id=" +
			//         this.$route.params.id
			//     )
			//     .then((response) => {
			//       this.schedule = response.data.data.schedule;
			//       if (this.schedule.category === 4) {
			//         this.$router.push(`/class/${this.$route.params.id}/session`);
			//       }
			//     })
			//     .catch((err) => console.error(err.response));
		},
		async addAttendance() {
			await this.$http
				.post('/v1/attendance/student', {
					user_id: parseInt(this.userID),
					class_id: parseInt(this.$route.params.id),
				})
				.then(() => {
					window.location.href =
						'/class/' + this.$route.params.id + '/detail';
				})
				.catch((err) => console.error(err.response));
		},
		async getAttendance() {
			this.attendace = {
				id: 4990,
				class_id: 7,
				user_id: 481,
				sequence: 1,
				start_date: '-',
				end_date: '-',
			};
			this.isAbsen = this.attendance.start_date !== '-';
			if (this.isAbsen) {
				this.doneAbsen = this.attendance.end_date !== '-';
			}
			// await this.$http
			//   .get(
			//     "/v1/user-attendance?user_id=" +
			//       this.userID +
			//       "&class_id=" +
			//       this.$route.params.id
			//   )
			//   .then((response) => {
			//     this.attendance = response.data.data.attendance;
			//     if (this.attendance.start_date == "00:00") {
			//       this.attendance.start_date = "-";
			//     } else {
			//       this.attendance.start_date =
			//         response.data.data.attendance.start_date + " WIB";
			//     }

			//     if (this.attendance.end_date == "00:00") {
			//       this.attendance.end_date = "-";
			//     } else {
			//       this.attendance.end_date =
			//         response.data.data.attendance.end_date + " WIB";
			//     }
			//     this.isAbsen = this.attendance.start_date !== "-";
			//     if (this.isAbsen) {
			//       this.doneAbsen = this.attendance.end_date !== "-";
			//     }
			//   })
			//   .catch((err) => console.error(err.response));
		},
		async getAssesmentByUserClass() {
			await this.$http
				.get(
					'/v1/user-assesment/detail?user_id=' +
						this.userID +
						'&class_id=' +
						this.$route.params.id,
				)
				.then((response) => {
					this.assesment = response.data.data;
					if (response.data.data == 'NO_DATA') {
						this.isAssesmentAvailable = true;
					}
				})
				.catch((err) => console.error(err.response));
		},
		goToPage(e) {
			window.open(
				e,
				'_blank', // <- This is what makes it open in a new window.
			);
		},
		toGo(herf) {
			window.location.href = herf;
		},
		goToCurrentPage(e, g) {
			window.location.href =
				'/question/' + this.$route.params.id + '?c=' + e + '&g=' + g;
		},
	},
	mounted() {
		if (this.$route.query.status == 'tamat') {
			this.items[0].text = 'Riwayat Kelas';
			this.items[0].href = '/history-class';
			this.items[1].text = 'Detail Riwayat Kelas';
		}

		this.getClass();
		this.getAttendance();
		// this.getAssesmentByUserClass();
	},
};
</script>

<style scoped>
.absent {
	align-items: center;
	justify-content: center;
	color: #36373f;
	padding: 20px 40px;
}
.btn-radius.confirmed:disabled {
	background-color: #ffffff !important;
	color: #162ca2 !important;
}
.btn-radius.confirmed:disabled .white--text {
	color: #162ca2 !important;
}
.container-detail {
	background-color: #f5f6f9;
}
.list-scrumb {
	display: flex;
	align-items: center;
	gap: 5px;
}
.scrumb-text {
	font-size: 18px;
	font-weight: 600;
	font-size: 13px;
	line-height: 18px;
	font-family: 'Poppins', sans-serif;
	color: #162ca2;
}
.inactive-scrumb {
	color: #575966;
	font-weight: 400;
}
.container-label-top {
	display: flex;
	gap: 8px;
}
.label-top {
	background-color: #f1f6ff;
	padding: 5px 12px;
	border-radius: 24px;
	color: #36373f;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
}
.title-scrumb {
	color: #7b7e8c;
	font-weight: 400;
}
.title-card {
	margin-top: 10px;
	display: block;
	color: #36373f;
	font-weight: 700;
	font-size: 20px;
	line-height: 30px;
}
.box-info-card {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
}
.box-info-card span {
	color: #36373f;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
}
.session-survey {
	display: flex;
	align-items: center;
	gap: 12px;
	margin-top: 24px;
	padding: 8px 16px;
	border-radius: 10px;
	border: 1px solid #162ca2;
	background-color: white;
}
.session-survey:hover {
	background-color: #f5f6ff;
}
.session-survey span {
	color: #162ca2;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
}
.modal-title {
	font-family: 'Poppins', sans-serif;
	color: #36373f;
	font-weight: 700;
	font-size: 20px;
	line-height: 30px;
}
.modal-body {
	font-family: 'Poppins', sans-serif;
	color: #36373f;
	font-size: 16px;
	line-height: 32px;
	margin-top: 16px;
}
.container-info {
	background-color: white;
	padding: 24px 40px;
	border-radius: 16px;
}
.container-presensi-heading,
.container-activity-heading,
.container-materi-heading,
.container-evaluation-heading {
	display: flex;
	flex-direction: column;
	gap: 4px;
}
.presensi-heading,
.activity-heading,
.materi-heading,
.evaluation-heading {
	display: flex;
	align-items: center;
	gap: 8px;
}
.presensi-heading h2,
.activity-heading h2,
.materi-heading h2,
.evaluation-heading h2 {
	color: #36373f;
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	font-size: 18px;
	line-height: 26px;
	margin: 0;
}
.presensi-heading > span,
.activity-heading > span,
.materi-heading > span,
.evaluation-heading > span {
	color: #575966;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
}
.container-box-presensi,
.container-box-evaluation {
	margin-top: 32px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.box-presensi {
	padding: 24px 40px;
	border: 1px solid #d3d6e1;
	border-radius: 16px;
	max-width: 60%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.box-presensi > p {
	color: #36373f;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	margin: 0;
}
.action-presensi {
	display: flex;
	align-items: center;
	gap: 16px;
}
.time-presensi {
	padding: 15px 90px;
	border-radius: 16px;
	background-color: #f7f9fd;
	font-family: 'Poppins', sans-serif;
	color: #7b7e8c;
	text-align: center;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}
.btn-confirmation-presensi {
	background-color: #162ca2;
	border-radius: 12px;
	padding: 16px 35px;
	color: #ffffff;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 1px;
	width: 200px;
}
.btn-confirmation-presensi:hover {
	background-color: #283ebc;
}
.btn-inactive-presensi {
	width: 200px;
	display: flex;
	align-items: center;
	gap: 4px;
	padding: 8px 13px;
	justify-content: center;
}
.btn-inactive-presensi span {
	color: #7b7e8c;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
}
.btn-activity,
.btn-materi,
.btn-test,
.btn-test-inactive {
	border: 1px solid #162ca2;
	border-radius: 12px;
	padding: 12px 47px;
	width: 210px;
	display: flex;
	align-items: center;
	background-color: white;
	justify-content: center;
	gap: 8px;
}
.btn-test-inactive {
	border: none;
}
.btn-activity:hover,
.btn-materi:hover,
.btn-test:hover {
	background-color: #f5f6ff;
}
.btn-activity span,
.btn-materi span,
.btn-test span {
	color: #162ca2;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
}
.btn-test-inactive span {
	color: #7b7e8c;
	font-family: 'Poppins', sans-serif;
	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
}
.box-evaluation {
	background-color: #f7f9fd;
	padding: 24px 40px;
	border-radius: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.evaluation-test {
	display: flex;
	flex-direction: column;
	gap: 4px;
}
.evaluation-test p {
	margin: 0;
	color: #36373f;
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	font-size: 16px;
	line-height: 22px;
}
.evaluation-test span {
	color: #575966;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 13px;
	line-height: 22px;
}
</style>
