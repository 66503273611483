<template>
    <div>
        <DesktopActiveClassEvent v-if="!isMobile" />
        <MobileActiveClassEvent v-else />
    </div>
</template>

<script>
import DesktopActiveClassEvent from '@/components/ActiveClassEvent/Desktop/ActiveClassEvent'
import MobileActiveClassEvent from '@/components/ActiveClassEvent/Mobile/ActiveClassEvent'
export default {
  components: {
    DesktopActiveClassEvent,
    MobileActiveClassEvent
  },
  data() {
    return{
      isMobile: false
    }
  },
  methods: {
      handleView() {
          this.isMobile = screen.width <= 990
      }
  },
  beforeMount() {
    this.handleView()
    // this.$gtag.pageview(this.$route)
  }
}
</script>