<template>
  <div class="d-flex navbar-custom" v-if="activeSideBar">
    <div>
      <div class="left-navbar-top">
        <div class="d-flex logo-div">
          <img src="/img/logo-hc.png" width="34px" height="42px" />
        </div>
        <div class="long-line"></div>

        <div
          style="text-align: center; cursor: pointer; margin-top: 25px"
          :class="{ actived2: layout === 'Beranda' }"
          @click="homeMenu"
        >
          <div>
            <!-- <img
              :src="
                layout == 'Beranda'
                  ? '/img/other/ic_home.png'
                  : '/img/other/ic_home.png'
              "
              width="25px"
              height="26px"
            /> -->

            <home />
          </div>
          <div style="font-size: 10px; color: white"><span>Beranda</span></div>
        </div>

        <div
          style="text-align: center; margin-top: 30px; cursor: pointer"
          :class="{ actived2: isActivity === true || parentActivity === true }"
          @click="classMenu"
        >
          <div>
            <!-- <img
              :src="
                isActivity === true || parentActivity === true
                  ? '/img/other/ic_activity_blue.png'
                  : '/img/other/ic_activity.png'
              "
              width="25px"
              height="26px"
            /> -->
            <book />
          </div>
          <div style="font-size: 10px; color: white"><span>Beranda</span></div>
        </div>
      </div>

      <div class="left-navbar-bottom d-flex flex-col">
        <div
          style="border-top: 1px solid white; height: 10px; width: 100%"
        ></div>
        <div class="name-circle d-flex" @click="profileMenu">
          <span>{{ initialName }}</span>
        </div>
      </div>
    </div>

    <div class="right-navbar" v-if="isActivity">
      <h3 class="title">Aktivitas</h3>

      <div
        class="d-flex sub-menu"
        :class="{ actived: layout === 'Kelas Aktif' }"
        @click="classMenu"
      >
        <div style="margin-top: 4px">
          <img
            :src="
              layout === 'Kelas Aktif'
                ? '/img/other/ic_active_class_blue.png'
                : '/img/other/ic_active_class.png'
            "
            width="32px"
            height="32px"
          />
        </div>
        <div style="margin-left: 20px"><span>Kelas Aktif</span></div>
      </div>

      <div
        class="d-flex sub-menu"
        :class="{ actived: layout === 'Penilaian' }"
        @click="assesmentMenu"
      >
        <div style="margin-top: 4px">
          <img
            :src="
              layout === 'Penilaian'
                ? '/img/other/ic_assesment_blue.png'
                : '/img/other/ic_assesment.png'
            "
            width="32px"
            height="32px"
          />
        </div>
        <div style="margin-left: 20px"><span>Penilaian</span></div>
      </div>

      <div
        class="d-flex sub-menu"
        :class="{ actived: layout === 'Sertifikat' }"
        @click="certificateMenu"
      >
        <div style="margin-top: 4px">
          <img
            :src="
              layout === 'Sertifikat'
                ? '/img/other/ic_certificate_blue.png'
                : '/img/other/ic_certificate.png'
            "
            width="32px"
            height="32px"
          />
        </div>
        <div style="margin-left: 20px"><span>Sertifikat</span></div>
      </div>

      <div
        class="d-flex sub-menu"
        :class="{ actived: layout === 'Ulasan' }"
        @click="reviewClassMenu"
      >
        <div style="margin-top: 4px">
          <img
            :src="
              layout === 'Ulasan'
                ? '/img/other/ic_chat_blue.png'
                : '/img/other/ic_chat.png'
            "
            width="32px"
            height="32px"
          />
        </div>
        <div style="margin-left: 20px"><span>Reflective Journal</span></div>
      </div>
    </div>

    <div class="right-navbar" v-if="isProfile">
      <h3 class="title">Profil</h3>

      <div
        class="d-flex sub-menu"
        :class="{ actived: layout === 'Profile' }"
        @click="profileMenu"
      >
        <div style="margin-top: 4px">
          <img
            :src="
              layout === 'Profile'
                ? '/img/other/ic_profile_blue.png'
                : '/img/other/ic_profile.png'
            "
            width="32px"
            height="32px"
          />
        </div>
        <div style="margin-left: 20px"><span>Informasi Akun</span></div>
      </div>

      <div
        class="d-flex sub-menu"
        :class="{ actived: layout === 'Pengaturan' }"
        @click="settingMenu"
      >
        <div style="margin-top: 4px">
          <img
            :src="
              layout === 'Pengaturan'
                ? '/img/icons/ic_setting_blue.png'
                : '/img/icons/ic_setting.png'
            "
            width="32px"
            height="32px"
          />
        </div>
        <div style="margin-left: 20px"><span>Pengaturan</span></div>
      </div>

      <div class="long-line"></div>

      <div
        class="d-flex sub-menu"
        :class="{ actived: layout === 'FAQ' }"
        @click="faqMenu"
      >
        <div style="margin-top: 4px">
          <img
            :src="
              layout === 'FAQ'
                ? '/img/other/ic_faq_blue.png'
                : '/img/other/ic_faq.png'
            "
            width="32px"
            height="32px"
          />
        </div>
        <div style="margin-left: 20px"><span>FAQ</span></div>
      </div>

      <div
        class="d-flex sub-menu"
        :class="{ actived: layout === 'AboutUs' }"
        @click="aboutUsMenu"
      >
        <div style="margin-top: 4px">
          <img
            :src="
              layout === 'AboutUs'
                ? '/img/other/ic_about_us_blue.png'
                : '/img/other/ic_about_us.png'
            "
            width="32px"
            height="32px"
          />
        </div>

        <div style="margin-left: 20px"><span>Tentang Kami</span></div>
      </div>

      <div class="long-line"></div>

      <div
        class="d-flex sub-menu"
        :class="{ actived: layout === 'PrivacyTerms' }"
        @click="privacyTermsMenu"
      >
        <div style="margin-top: 4px">
          <img
            :src="
              layout === 'PrivacyTerms'
                ? '/img/other/ic_doc_blue.png'
                : '/img/other/ic_doc.png'
            "
            width="25"
            height="25"
          />
          <!-- <home /> -->
        </div>
        <div style="margin-left: 20px; font-size: 14px">
          <span>Ketentuan Privasi</span>
        </div>
      </div>

      <div
        class="d-flex sub-menu"
        :class="{ actived: layout === 'TermCondition' }"
        @click="termConditionMenu"
      >
        <div style="margin-top: 4px">
          <img
            :src="
              layout === 'TermCondition'
                ? '/img/other/ic_doc_blue.png'
                : '/img/other/ic_doc.png'
            "
            width="25"
            height="25"
          />
        </div>
        <div style="margin-left: 20px; font-size: 14px">
          <span>Syarat & Ketentuan</span>
        </div>
      </div>

      <div class="long-line"></div>

      <!-- <div class="d-flex sub-menu" @click="dashboardCRM" v-if="isAdmin">
                <div style="margin-top:4px"><img :src="'/img/icons/ic_setting.png'" width="32px" height="32px"></div>
                <div style="margin-left: 20px;"><span>CRM Dashabord</span></div>
                <div class="long-line" v-if="isAdmin"></div> -->

      <div class="d-flex sub-menu" @click="logout">
        <div style="margin-top: 4px">
          <img src="/img/other/ic_logout.png" width="32px" height="32px" />
        </div>

        <div style="margin-left: 20px"><span>Keluar</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";
import home from "../../../../public/img/icons/home.vue";
import book from "../../../../public/img/icons/book.vue";
export default {
  components: {
    home,
    book,
  },
  data() {
    return {
      layout: "Beranda",
      isActivity: false,
      parentActivity: false,
      isProfile: false,
      isAdmin: false,
      activeSideBar: true,
      initialName: "",
      user: localStorage.getItem("user"),
    };
  },

  watch: {
    $route(to) {
      if (to.path.includes("/verification/")) {
        this.activeSideBar = false;
      } else {
        this.activeSideBar = true;
      }
    },
  },

  methods: {
    dashboardCRM() {
      window.open(
        // 'http://localhost:8082/home/'+this.user,
        "https://dashboard.hicolleagues.com/home/" + this.user,
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    homeMenu() {
      (this.layout = "Beranda"), (this.isActivity = false);
      this.$router.push("/home").catch(() => {});
      this.isProfile = false;
      this.parentActivity = false;
    },
    classMenu() {
      (this.layout = "Kelas Aktif"), (window.location.href = "/active-class");
      this.isActivity = true;
      this.isProfile = false;
    },
    assesmentMenu() {
      (this.layout = "Penilaian"),
        this.$router.push("/assesment").catch(() => {});
      this.isActivity = true;
      this.isProfile = false;
    },
    certificateMenu() {
      (this.layout = "Sertifikat"),
        this.$router.push("/certificate").catch(() => {});
      this.isActivity = true;
      this.isProfile = false;
    },
    historyClassMenu() {
      (this.layout = "Riwayat Kelas"),
        this.$router.push("/history-class").catch(() => {});
      this.isActivity = true;
      this.isProfile = false;
    },
    reviewClassMenu() {
      (this.layout = "Ulasan"), this.$router.push("/review").catch(() => {});
      this.isActivity = true;
      this.isProfile = false;
    },
    profileMenu() {
      (this.layout = "Profile"), this.$router.push("/profile").catch(() => {});
      this.isActivity = false;
      this.isProfile = true;
      this.parentActivity = false;
    },
    faqMenu() {
      (this.layout = "FAQ"), this.$router.push("/faq").catch(() => {});
      this.isActivity = false;
      this.isProfile = true;
    },
    aboutUsMenu() {
      (this.layout = "AboutUs"), this.$router.push("/about-us").catch(() => {});
      this.isActivity = false;
      this.isProfile = true;
    },
    privacyTermsMenu() {
      (this.layout = "PrivacyTerms"),
        this.$router.push("/privacy-terms").catch(() => {});
      this.isActivity = false;
      this.isProfile = true;
    },
    termConditionMenu() {
      (this.layout = "TermCondition"),
        this.$router.push("/term-condition").catch(() => {});
      this.isActivity = false;
      this.isProfile = true;
    },
    settingMenu() {
      (this.layout = "Pengaturan"),
        this.$router.push("/setting").catch(() => {});
      this.isActivity = false;
      this.isProfile = true;
    },
    logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("account");
      window.location.href = "https://www.hicolleagues.com";
    },
    handleCheckFirst() {
      this.layout = this.$route.name;
      if (
        this.$route.name == "Kelas Aktif" ||
        this.$route.name == "Penilaian" ||
        this.$route.name == "Sertifikat" ||
        this.$route.name == "Riwayat Kelas" ||
        this.$route.name == "Review"
      ) {
        this.isActivity = true;
      } else {
        this.isActivity = false;
      }

      if (
        this.$route.name == "Detail Nilai Pelatihan" ||
        this.$route.name == "Detail Kelas" ||
        this.$route.name == "Question"
      ) {
        this.parentActivity = true;
      }

      if (
        this.$route.name == "Pengaturan" ||
        this.$route.name == "FAQ" ||
        this.$route.name == "AboutUs" ||
        this.$route.name == "Profile" ||
        this.$route.name == "PrivacyTerms" ||
        this.$route.name == "TermCondition"
      ) {
        this.layout = this.$route.name;
        this.isProfile = true;
      }

      var name = "";
      var data = JSON.parse(localStorage.getItem("account"));
      if (data != null) {
        name = data["full_name"].split(" ");
      } else {
        name = this.$decode_token["name"].split(" ");
      }

      if (name.length >= 2) {
        this.initialName = name[0][0] + "" + name[1][0];
      } else {
        this.initialName = name[0][0];
      }
      try {
        if (this.$decode_token["user_type_id"] == 1) {
          this.isAdmin = true;
        }
        if (this.$route.path.includes("/verification/")) {
          this.activeSideBar = false;
        } else {
          this.activeSideBar = true;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },

  beforeMount() {
    if (this.$route.params.token != null) {
      localStorage.setItem("user", this.$route.params.token);
      this.$forceUpdate();

      let decoded = VueJwtDecode.decode(this.$route.params.token);
      this.$decode_token = decoded;
    }
  },

  mounted() {
    this.handleCheckFirst();
  },
};
</script>

<style scoped>
.flex-col {
  flex-direction: column;
}
.name-circle {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(111.75deg, #179eff -0.58%, #162ca2 100.82%);
}

.navbar-custom {
  max-height: 100vh;
  position: fixed;
  background: #162ca2;
  z-index: 1;
}

.sub-menu {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  margin-top: 10px;
  padding-left: 15px !important;
  cursor: pointer;
}

.actived {
  color: #162ca2 !important;
  background: #f1f6ff;
  border-radius: 10px;
  padding: 8px;
  font-weight: bold;
}

.actived2 {
  color: f1f6ff !important;
  background: #3f52b4;
  border-radius: 10px;
  padding: 8px;
  font-weight: bold;
}

.left-navbar-top {
  width: 80px;
  height: 90vh;
  border: 1px solid #162ca2;
  border-right-color: #d9d9d9;
  padding-left: 10px;
  padding-right: 10px;
}

.left-navbar-bottom {
  width: 80px;
  height: 10vh;
  border-right-color: #d9d9d9;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
  align-items: center;
}

.right-navbar {
  width: 220px;
  height: 100vh;
  border: 1px solid #ffffff;
  border-right-color: #d9d9d9;
  background-color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.logo-div {
  margin-top: 30px;
  margin-bottom: 30px;
  justify-content: center;
}

.line {
  height: 1px;
  width: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  background: #d9d9d9;
}

.title {
  margin-top: 50px;
  margin-bottom: 35px;
  font-weight: 600;
  font-size: 26px;
  line-height: 35px;
}
</style>
