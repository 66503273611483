<template>
  <div>
      <DesktopHomeEvent v-if="!isMobile" />
      <MobileHomeEvent v-else />
  </div>
</template>

<script>
import DesktopHomeEvent from '@/components/Event/Desktop/HomeEvent'
import MobileHomeEvent from '@/components/Event/Mobile/HomeEvent'
export default {
  components: {
    DesktopHomeEvent,
    MobileHomeEvent
  },
  data() {
    return{
      isMobile: false
    }
  },
  methods: {
      handleView() {
          this.isMobile = screen.width <= 990
      }
  },
  beforeMount() {
    this.handleView()
  }
}
</script>