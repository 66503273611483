<template>
    <div>
        <DesktopDetailClassEvent v-if="!isMobile" />
        <MobileDetailClassEvent v-else />
    </div>
  </template>
  
  <script>
  import DesktopDetailClassEvent from '@/components/DetailClass/Desktop/DetailClassEvent'
  import MobileDetailClassEvent from '@/components/DetailClass/Mobile/DetailClassEvent'
  export default {
    components: {
      DesktopDetailClassEvent,
      MobileDetailClassEvent
    },
    data() {
      return{
        isMobile: false
      }
    },
    methods: {
        handleView() {
            this.isMobile = screen.width <= 990
        }
    },
    beforeMount() {
      this.handleView()
    }
  }
  </script>