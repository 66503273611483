<template>
  <DesktopSurveyUlasan></DesktopSurveyUlasan>
</template>

<script>
import DesktopSurveyUlasan from "../../components/SurveyUlasan/Desktop/DesktopSurveyUlasan.vue";
export default {
  name: "SurveyUlasanPage",
  components: { DesktopSurveyUlasan },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    handleView() {
      this.isMobile = screen.width <= 990;
    },
  },
  beforeMount() {
    this.handleView();
  },
};
</script>
