import moment from "moment";
import VueJwtDecode from 'vue-jwt-decode';

import { crmAPI } from "./api";

export default {
  setSessionPrakerja(data) {
    const praKey = JSON.parse(sessionStorage.getItem("pra-key"));
    const search = (obj) => obj.class_id == data.class_id;
    const idx = praKey.findIndex(search);
    if (idx >= 0) {
      praKey[idx].redeem_code = data.redeem_code;
      praKey[idx].sequence = data.session;
      sessionStorage.setItem("pra-key", JSON.stringify(praKey));
    } else {
      sessionStorage.setItem(
        "pra-key",
        JSON.stringify(
          praKey.concat({
            redeem_code: data.redeem_code,
            sequence: data.session,
            class_id: data.class_id,
          })
        )
      );
    }
    sessionStorage.setItem("class_id", data.class_id);
  },
  setLocalPrakerja(data) {
    const praKey = JSON.parse(localStorage.getItem("pra-key"));
    const search = (obj) => obj.class_id == data.class_id;
    const idx = praKey.findIndex(search);
    if (idx >= 0) {
      praKey[idx].redeem_code = data.redeem_code;
      praKey[idx].sequence = data.session;
      localStorage.setItem("pra-key", JSON.stringify(praKey));
    } else {
      localStorage.setItem(
        "pra-key",
        JSON.stringify(
          praKey.concat({
            redeem_code: data.redeem_code,
            sequence: data.session,
            class_id: data.class_id,
          })
        )
      );
    }
    sessionStorage.setItem("class_id", data.class_id);
  },
  async getRedeem(classId = 0) {
    const data1 = JSON.parse(localStorage.getItem("pra-key"));
    const data2 = JSON.parse(sessionStorage.getItem("pra-key"));
    const na1 = data1.find((item) => item.class_id == classId);
    if (na1) {
      return na1.redeem_code;
    }
    const na2 = data2.find((item) => item.class_id == classId);
    if (na2) {
      return na2.redeem_code;
    }
    try {
      const data = localStorage.getItem("user")
      const decoded = VueJwtDecode.decode(data)
      const resp = await crmAPI.get(`/v1/verification-code/${decoded["id"]}/${classId}?attendance_status=1`)  
      return resp.data.data.verification_code.redeem_code
    } catch (error) {
      console.log(error);
      return null; 
    }
  },
  getSession(classId = 0) {
    const data1 = JSON.parse(localStorage.getItem("pra-key"));
    const data2 = JSON.parse(sessionStorage.getItem("pra-key"));
    const na1 = data1.find((item) => item.class_id == classId);
    if (na1) {
      return Number(na1.sequence);
    }
    const na2 = data2.find((item) => item.class_id == classId);
    if (na2) {
      return Number(na2.sequence);
    }
    return null;
  },
  getClass() {
    return sessionStorage.getItem("class_id");
  },
  removeSessionCodePrakerja() {
    sessionStorage.removeItem("class_id");
  },
  goLink(link) {
    window.open(link);
  },
  filterLinkClickup(link) {
    const checkExtention = link.match(/.doc|.docx|.xls|.xlsx|.ppt|.pptx/);
    if (checkExtention) {
      const paths = link.split("/");
      return `https://app.clickup.com/9003038375/office/p/t9003038375/${
        paths[paths.length - 2]
      }?filename=${paths[paths.length - 1]}`;
    }
    return link;
  },
  applyFormatFile() {
    return ".jpg,.jpeg,.png,.doc,.docx,.xls,xlsx,.txt,.pdf,.ppt,.pptx";
  },
  dateFormat(date, format = "DD MMMM YYYY, hh:mm", timeZone = "WIB") {
    return moment(date).utc().format(format) + " " + timeZone;
  },
  formatLevel(level) {
    if (!level) return "Dasar";

    level = level.toLowerCase();
    if (level === "expert") {
      return "Profesional";
    } else if (level === "intermediate") {
      return "Menengah";
    } else {
      return "Dasar";
    }
  },
  testerLive(email) {
    const listEmailTesterLive = [
      "tester10@hicolleagues.com",
      "tester11@hicolleagues.com",
      "tester12@hicolleagues.com",
      "tester13@hicolleagues.com",
      "tester14@hicolleagues.com",
      "tester15@hicolleagues.com",
      "tester16@hicolleagues.com",
      "tester17@hicolleagues.com",
      "tester18@hicolleagues.com",
      "tester19@hicolleagues.com",
    ];
    const isTesterLive = listEmailTesterLive.find((item) => item === email);
    if (isTesterLive) {
      return true;
    } else {
      return false;
    }
  },
  categoryName(id) {
    if (id == 0) {
      return "Mini Bootcamp"
    } else if (id == 1) {
      return "Public Training | Technology"
    } else if (id == 2) {
      return "Public Training | Bahasa"
    } else if (id == 3) {
      return "Public Training | Softskill"
    } else if (id == 4) {
      return "Prakerja"
    } else if (id == 5) {
      return "Bootcamp"
    } else if (id == 6) {
      return "Akselerasi"
    }
  }, 
  formatMode(value) {
    if (!value) return value
  if (value.toLowerCase() === "online") {
    return "Zoom"
  } else if  (value.toLowerCase() === "offline") {
    return "Di tempat"
  } else {
    return value
  }
  },
  getFile(file) {
    const dataFile = file.target.files[0];
    const size = dataFile.size / 1048576; // format MB

    return [dataFile, size];
  },
  listIdTester() {
    return [11, 481, 482, 483, 484, 485, 486, 487, 488, 489];
  },
  loadImage(image) {
    if (image && image != "-") return image
    return '/img/other/ic_profile_null.png'
  }
};
