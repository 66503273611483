<template>
  <div class="container-custom">
    <v-breadcrumbs
      divider=">"
      :items="items"
      class="breadcrumbs"
    ></v-breadcrumbs>

    <div class="judul-survey-container">
      <h2>Survey dan Ulasan</h2>
      <p>Bagikan pengalamanmu tentang kelas pelatihan ini</p>
    </div>

    <div style="margin: 32px 140px">
      <div
        v-for="event in itemsButton"
        :key="event.id"
        class="nilai-survey-container"
      >
        <p class="nilai-survey-text">
          {{ event.text }}
        </p>
        <div class="nilai-survey-text-btn-container">
          <p class="text-number-survey" style="margin: 0px 32px 0px 0px">
            Sangat Tidak Bermanfaat
          </p>
          <div
            class="nilai-survey-btn-container"
            v-for="item in event.items"
            :key="item.id"
          >
            <p class="number-survey">
              {{ item.id }}
            </p>
            <button
              class="button-survey"
              :class="{ buttonNonactive: !item.isActive }"
              @click="handleClick(event.id, item.id)"
            >
              <div class="button-content">
                <div class="circle" :class="{ active: item.isActive }"></div>
              </div>
            </button>
          </div>
          <p class="text-number-survey" style="margin: 0px 0px 0px 32px">
            Sangat Bermanfaat
          </p>
        </div>
      </div>
      <div class="nilai-survey-container-2">
        <p class="nilai-survey-text">Tulis komentar/ulasan lain</p>
        <div class="nilai-survey-text-btn-container">
          <input
            type="text"
            class="input-survey"
            placeholder="Pelatihan berjalan baik dan kondusif, mentor menjelaskan secara interaktif!"
          />
        </div>
      </div>
    </div>

    <div class="done-container">
      <p>Apakah HiColers yakin mengumpulkan data ini?</p>
      <Button name="KIRIM" width="296px" height="48px" />
    </div>
  </div>
</template>

<script>
import Button from "@/components/others/Button.vue";
export default {
  name: "DesktopSurveyUlasan",
  data() {
    return {
      items: [
        {
          text: "Aktivitas",
          disabled: true,
          href: "/active-class/event",
        },
        {
          text: "Kelasku",
          disabled: true,
          href: "/penilaian/event",
        },
        {
          text: "Detail Kelas",
          disabled: true,
          href: "/penilaian/event",
        },
        {
          text: "Survey & Ulasan",
          disabled: true,
        },
      ],

      itemsButton: [
        {
          id: 1,
          text: "Apakah materi pelatihan bermanfaat dalam proses pembelajaranmu?",
          items: [
            { id: 1, isActive: true },
            { id: 2, isActive: true },
            { id: 3, isActive: true },
            { id: 4, isActive: true },
            { id: 5, isActive: true },
          ],
        },
        {
          id: 2,
          text: "Bagaimana performa trainer saat menyampaikan materi pelatihan?",
          items: [
            { id: 1, isActive: true },
            { id: 2, isActive: true },
            { id: 3, isActive: true },
            { id: 4, isActive: true },
            { id: 5, isActive: false },
          ],
        },
        {
          id: 3,
          text: "Bagaimana performa host saat sesi pelatihan berlangsung?",
          items: [
            { id: 1, isActive: true },
            { id: 2, isActive: true },
            { id: 3, isActive: true },
            { id: 4, isActive: true },
            { id: 5, isActive: false },
          ],
        },
        {
          id: 4,
          text: "Bagaimana kualitas fasilitas kelas pelatihan HiColleagues saat sesi pelatihan berlangsung?",
          items: [
            { id: 1, isActive: true },
            { id: 2, isActive: true },
            { id: 3, isActive: true },
            { id: 4, isActive: false },
            { id: 5, isActive: false },
          ],
        },
      ],
    };
  },
  components: { Button },
  methods: {
    handleClick(questionId, activeId) {
      const question = this.itemsButton.find((q) => q.id === questionId);
      if (!question) return;

      question.items = question.items.map((item) => {
        return { ...item, isActive: item.id <= activeId };
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.container-custom {
  padding-top: 24px;
}

.judul-survey-container {
  margin: 24px 0px 0px 0px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid#ACAFBF;
}
.judul-survey-container h2 {
  color: #36373f;
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
}
.judul-survey-container p {
  margin: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #575966;
}

.nilai-survey-container {
  background-color: white;
  height: 178px;
  border-radius: 16px;
  padding: 24px 32px;
  margin-bottom: 16px;
}
.nilai-survey-container-2 {
  background-color: white;
  border-radius: 16px;
  padding: 24px 32px;
  margin-bottom: 16px;
}
.nilai-survey-text {
  margin: 0px;
  color: #36373f;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}
.nilai-survey-text-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0px;
}
.nilai-survey-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.number-survey {
  margin: 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
}
.text-number-survey {
  margin: 0px;
  color: #36373f;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.button-survey {
  background-color: #2196f3;
  border: 1px solid #2196f3;
  cursor: pointer;
  margin: 0 10px;
  border-radius: 999px;
}
.button-survey.buttonNonactive {
  background-color: #ffff;
  border: 1px solid #7b7e8c;
  cursor: pointer;
  border-radius: 999px;
}
.button-content {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 999px;
  width: 32.5px;
  height: 32.5px;
}
.circle {
  width: 28.5px;
  height: 28.5px;
  border-radius: 999px;
  background-color: #ffff;
}
.circle.active {
  width: 15px;
  height: 15px;
}
.input-survey {
  width: 960px;
  border: 1px solid #162ca2;
  border-radius: 12px;
  padding: 16px 24px 56px 24px;
}

.done-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.done-container p {
  margin: 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #36373f;
  margin-bottom: 24px;
}

.breadcrumbs {
  padding: 0px;
  margin-left: 0px;
  margin-bottom: 35px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
::v-deep .v-breadcrumbs__divider {
  color: #162ca2 !important;
}
::v-deep .v-breadcrumbs__item {
  color: #162ca2;
}
::v-deep div.v-breadcrumbs__item {
  color: #7b7e8c !important;
}
</style>
