<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.1902 14.0598L19.0602 12.1798C18.8102 11.7698 18.5902 10.9798 18.5902 10.4998V8.6298C18.5902 4.9998 15.6402 2.0498 12.0202 2.0498C8.3902 2.0598 5.4402 4.9998 5.4402 8.6298V10.4898C5.4402 10.9698 5.2202 11.7598 4.9802 12.1698L3.8502 14.0498C3.4202 14.7798 3.3202 15.6098 3.5902 16.3298C3.8602 17.0598 4.4702 17.6398 5.2702 17.8998C6.3502 18.2598 7.4402 18.5198 8.5502 18.7098C8.6602 18.7298 8.7702 18.7398 8.8802 18.7598C9.0202 18.7798 9.1702 18.7998 9.3202 18.8198C9.5802 18.8598 9.8402 18.8898 10.1102 18.9098C10.7402 18.9698 11.3802 18.9998 12.0202 18.9998C12.6502 18.9998 13.2802 18.9698 13.9002 18.9098C14.1302 18.8898 14.3602 18.8698 14.5802 18.8398C14.7602 18.8198 14.9402 18.7998 15.1202 18.7698C15.2302 18.7598 15.3402 18.7398 15.4502 18.7198C16.5702 18.5398 17.6802 18.2598 18.7602 17.8998C19.5302 17.6398 20.1202 17.0598 20.4002 16.3198C20.6802 15.5698 20.6002 14.7498 20.1902 14.0598ZM12.7502 9.9998C12.7502 10.4198 12.4102 10.7598 11.9902 10.7598C11.5702 10.7598 11.2302 10.4198 11.2302 9.9998V6.89981C11.2302 6.4798 11.5702 6.1398 11.9902 6.1398C12.4102 6.1398 12.7502 6.4798 12.7502 6.89981V9.9998Z" fill="#162CA2"/>
    <path d="M14.8302 20.01C14.4102 21.17 13.3002 22 12.0002 22C11.2102 22 10.4302 21.68 9.88018 21.11C9.56018 20.81 9.32018 20.41 9.18018 20C9.31018 20.02 9.44018 20.03 9.58018 20.05C9.81018 20.08 10.0502 20.11 10.2902 20.13C10.8602 20.18 11.4402 20.21 12.0202 20.21C12.5902 20.21 13.1602 20.18 13.7202 20.13C13.9302 20.11 14.1402 20.1 14.3402 20.07C14.5002 20.05 14.6602 20.03 14.8302 20.01Z" fill="#162CA2"/>
  </svg>
</template>

<script>
export default {
  name: 'NotificationIcon',
  props: {
    width: {
      type: Number,
      default: 32
    },
    height: {
      type: Number,
      default: 32
    }
  }
}
</script>