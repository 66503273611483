<template>
  <div>
    <PenilaianClassEvent v-if="!isMobile" />
  </div>
</template>

<script>
import PenilaianClassEvent from "../../components/PenilaianEvent/Desktop/PenilaianClassEvent.vue";
export default {
  components: {
    PenilaianClassEvent,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    handleView() {
      this.isMobile = screen.width <= 990;
    },
  },
  beforeMount() {
    this.handleView();
  },
};
</script>
