<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.1 17.75H5.89999C3.57999 17.75 2.25 16.42 2.25 14.1V2C2.25 1.59 2.59 1.25 3 1.25H21C21.41 1.25 21.75 1.59 21.75 2V14.1C21.75 16.42 20.42 17.75 18.1 17.75ZM3.75 2.75V14.1C3.75 15.59 4.40999 16.25 5.89999 16.25H18.09C19.58 16.25 20.24 15.59 20.24 14.1V2.75H3.75Z" fill="#7B7E8C"/>
    <path d="M22 2.75H2C1.59 2.75 1.25 2.41 1.25 2C1.25 1.59 1.59 1.25 2 1.25H22C22.41 1.25 22.75 1.59 22.75 2C22.75 2.41 22.41 2.75 22 2.75Z" fill="#7B7E8C"/>
    <path d="M8.00027 22.75C7.72027 22.75 7.46025 22.6 7.33025 22.34C7.14025 21.97 7.29028 21.52 7.67028 21.33L11.2503 19.54V17C11.2503 16.59 11.5903 16.25 12.0003 16.25C12.4103 16.25 12.7503 16.59 12.7503 17V20C12.7503 20.28 12.5903 20.54 12.3303 20.67L8.33025 22.67C8.23025 22.72 8.11027 22.75 8.00027 22.75Z" fill="#7B7E8C"/>
    <path d="M15.9999 22.7502C15.8899 22.7502 15.77 22.7202 15.67 22.6702L11.67 20.6702C11.3 20.4802 11.1499 20.0302 11.3299 19.6602C11.5199 19.2902 11.9699 19.1402 12.3399 19.3202L16.3399 21.3202C16.7099 21.5102 16.8599 21.9602 16.6799 22.3302C16.5399 22.6002 16.2699 22.7502 15.9999 22.7502Z" fill="#7B7E8C"/>
    <path d="M7.49993 11.7497C7.28993 11.7497 7.06994 11.6597 6.91994 11.4797C6.64994 11.1597 6.69992 10.6897 7.01992 10.4197L10.1699 7.78969C10.4599 7.54969 10.8299 7.44969 11.1799 7.50969C11.5399 7.56969 11.8499 7.78967 12.0399 8.10967L13.0899 9.85967L16.0199 7.4197C16.3399 7.1597 16.8099 7.19967 17.0799 7.51967C17.3499 7.83967 17.2999 8.30967 16.9799 8.57967L13.8299 11.2097C13.5399 11.4497 13.1699 11.5497 12.8199 11.4897C12.4599 11.4297 12.1499 11.2097 11.9599 10.8897L10.9099 9.1397L7.97994 11.5797C7.83994 11.6897 7.66993 11.7497 7.49993 11.7497Z" fill="#7B7E8C"/>
  </svg>
</template>

<script>
export default {
  name: "MediaIcon",
  props: {
    width: {
      type: Number,
      default: 32,
    },
    height: {
      type: Number,
      default: 32,
    },
  },
}
</script>