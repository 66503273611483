<template>
  <div class="container-custom-body">
    <Loading v-if="isLoading" />

    <div class="container-class">
    <div class="d-flex" style="margin-bottom: 40px; align-items: center">
      <div class="d-flex justify-start" style="width: 100%">
        <h4 class="class-schedule-text">Rapor Kelas</h4>
        <InformationForm />
      </div>
      <div class="d-flex justify-end" style="align-items: center">
        <span class="category-text">Kategori</span>
        <div style="width: 250px">
          <v-select
            v-model="category"
            :items="categories"
            item-text="name"
            item-value="value"
            dense
            outlined
            hide-details
            class="ma-2 select-category"
          ></v-select>
        </div>
      </div>
    </div>
    <div v-for="item in assesments" :key="item.class_id">
				<CardClass :data="item" :isMyClassPage="true" :isRaport="true" />
			</div>
    <!-- <div class="card-schedule" v-for="i in assesments" :key="i.id">
      <div class="d-flex justify-space-between my-4">
        <div>
          <div class="d-flex" style="gap: 20px">
            <div
              class="d-flex button-light-blue"
              v-if="i.category === 4"
              style="align-items: center; justify-content: center"
            >
              <img src="/img/other/ic_prakerja.png" width="69" height="22" />
            </div>
            <div
            class="d-flex button-light-blue"
            v-if="i.category === 4"
            style="align-items: center; justify-content: center;"
          >
            <span>Webinar</span>
          </div>
            <div class="button-light-blue" v-if="i.category !== 4">
              <span>Public Training</span>
            </div>
          </div>
          <div class="mb-2">
            <span class="title">{{ i.name }}</span>
          </div>
        </div>
        <div>
          <div class="button-grey" @click="goToPage(i.id, i.class_id)">
            <span>Lihat Detail</span>
          </div>
        </div>
      </div>

      <v-data-table
        :headers="i.category !== 4 ? headerAssesments : headerAssesmentsPrakerja"
        :items="i.detail_assesments"
        class="elevation-1"
        hide-default-footer
      >
        <template #item.pre_test_score="{value}">
          <div class="data-table">
            <div class="assesment-value">
              <span>{{ value }}</span>
            </div>
            <div :style="getColor(value)" class="hor-line"></div>
          </div>
        </template>

        <template #item.tpm_score="{value}">
          <div class="data-table">
            <div class="assesment-value">
              <span>{{ value }}</span>
            </div>
            <div :style="getColor(value)" class="hor-line"></div>
          </div>
        </template>
        
        <template #item.quiz_score="{value}">
          <div class="data-table">
            <div class="assesment-value">
              <span>{{ value }}</span>
            </div>
            <div :style="getColor(value)" class="hor-line"></div>
          </div>
        </template>

        <template #item.post_test_score="{value}">
          <div class="data-table">
            <div class="assesment-value">
              <span>{{ value }}</span>
            </div>
            <div :style="getColor(value)" class="hor-line"></div>
          </div>
        </template>

        <template #item.final_project_score="{value}">
          <div class="data-table">
            <div class="assesment-value">
              <span>{{ value }}</span>
            </div>
            <div :style="getColor(value)" class="hor-line"></div>
          </div>
        </template>

        <template #item.notes="{value}">
          <div style="width: 300px" class="data-table">
            <div class="note">
              <span>{{ value }}</span>
            </div>
          </div>
        </template>
      </v-data-table>
    </div> -->
  </div>
  </div>
</template>

<script>
import InformationForm from "@/components/Assesment/Desktop/Information";
import CardClass from '../../others/CardClass.vue';

export default {
  components: {
    InformationForm,
    CardClass
  },
  data() {
    return {
      categories: [
        {name: "Semua", value: 0},
        {name: "Bootcamp", value: 5},
        {name: "Prakerja", value: 4},
        {name: "Public Training", value: 1}
      ],
      isLoading: false,
      category: 0,
      assesments_list: [],
      assesments: [],
      headerAssesments: [
        {
          text: "Pre Test",
          value: "pre_test_score",
          sortable: false,
          align: "center",
        },
        {text: "Quiz (Rata-rata nilai)", value: "quiz_score", sortable: false, align: "center"},
        {
          text: "Pos Test",
          value: "post_test_score",
          sortable: false,
          align: "center",
        },
        {
          text: "Unjuk Keterampilan",
          value: "final_project_score",
          sortable: false,
          align: "center",
        },
        {text: "Catatan", value: "notes", sortable: false, align: "center"},
      ],
      headerAssesmentsPrakerja: [
        {
          text: "Pre Test",
          value: "pre_test_score",
          sortable: false,
          align: "center",
        },
        {text: "Quiz (Rata-rata nilai)", value: "quiz_score", sortable: false, align: "center"},
        {text: "TPM (Rata-rata nilai)", value: "tpm_score", sortable: false, align: "center"},
        {
          text: "Pos Test",
          value: "post_test_score",
          sortable: false,
          align: "center",
        },
        {
          text: "Unjuk Keterampilan",
          value: "final_project_score",
          sortable: false,
          align: "center",
        },
        {text: "Catatan", value: "notes", sortable: false, align: "center"},
      ],
    };
  },
  watch: {
    category(v) {
      if (!v) this.assesments = this.assesments_list 
      else this.assesments = this.assesments_list.filter((item) => item.category === v)
    }
  },
  methods: {
    getColor(e) {
      if (e <= 50) return "background:#B80F0A";
      else if (e >= 70 && e <= 79) return "background:#E5AF0B";
      else return "background:#01A368";
    },
    goToPage(e, class_id) {
      window.location.href = "/assesment/" + e + "/detail/" + class_id;
    },
    async getAssesment() {
      await this.$http
        .get("/v1/user-assesment?user_id=" + this.$decode_token["id"])
        .then((response) => {
          this.assesments = response.data.data.assesments;
          this.assesments_list = response.data.data.assesments
        })
        .catch((err) => console.error(err.response));
    },
  },
  mounted() {
    this.getAssesment();
  },
};
</script>

<style scoped>
.data-table {
  height: 80px;
  width: 100px;
  text-align: center;
  padding-top: 5px;
}

.hor-line {
  width: 40px;
  height: 6px;
  border-radius: 4px 4px 0px 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 28px;
}

.assesment-value {
  font-weight: bold;
  margin-top: 10px;
  font-size: 21px;
}

.note {
  font-size: 16px;
  line-height: 20px;
}

::v-deep .v-data-table-header {
  background: #f5f6f9;
  color: #36373f;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.theme--light.v-data-table {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px !important;
}

.container-history {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button-history {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 5px 10px;
}

.button-history:hover {
  background-color: #d3d6e1;
  border-radius: 10px;
}

.button-history p {
  margin: 0;
  color: #36373f;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.class-schedule-text {
  color: #36373f;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

.category-text {
  color: #36373f;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.container-class {
  background-color: white;
  padding: 40px 32px;
  border-radius: 24px;
}

.container-card {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.empty-class {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.empty-class > h3 {
  color: #7b7e8c;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-top: 16px;
}

.empty-class > p {
  max-width: 420px;
  color: #7b7e8c;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  margin-top: 4px;
}

.name-circle {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(111.75deg, #179eff -0.58%, #162ca2 100.82%);
}
.container-chat {
  margin-top: 10px;
  padding: 5px 19px;
  border-radius: 18px;
  position: relative;
  z-index: 10px;
  border: 1px solid #d3d6e1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-chat .content {
  display: flex;
  gap: 8px;
  align-items: center !important;
}
.select-category {
  background-color: white;
                color: #36373f;
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
}
</style>
