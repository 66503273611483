<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.88 18.9001C6.47 18.9001 6.13 18.5601 6.13 18.1501V16.0801C6.13 15.6701 6.47 15.3301 6.88 15.3301C7.29 15.3301 7.63 15.6701 7.63 16.0801V18.1501C7.63 18.5701 7.29 18.9001 6.88 18.9001Z" fill="#7B7E8C"/>
    <path d="M12 18.9C11.59 18.9 11.25 18.56 11.25 18.15V14C11.25 13.59 11.59 13.25 12 13.25C12.41 13.25 12.75 13.59 12.75 14V18.15C12.75 18.57 12.41 18.9 12 18.9Z" fill="#7B7E8C"/>
    <path d="M17.12 18.8997C16.71 18.8997 16.37 18.5597 16.37 18.1497V11.9297C16.37 11.5197 16.71 11.1797 17.12 11.1797C17.53 11.1797 17.87 11.5197 17.87 11.9297V18.1497C17.87 18.5697 17.54 18.8997 17.12 18.8997Z" fill="#7B7E8C"/>
    <path d="M6.87995 13.1804C6.53995 13.1804 6.23995 12.9504 6.14995 12.6104C6.04995 12.2104 6.28995 11.8004 6.69995 11.7004C10.38 10.7804 13.62 8.77041 16.09 5.90041L16.55 5.36041C16.82 5.05041 17.29 5.01041 17.61 5.28041C17.92 5.55041 17.96 6.02041 17.69 6.34041L17.23 6.88041C14.56 10.0004 11.04 12.1704 7.05995 13.1604C6.99995 13.1804 6.93995 13.1804 6.87995 13.1804Z" fill="#7B7E8C"/>
    <path d="M17.1199 9.51961C16.7099 9.51961 16.3699 9.17961 16.3699 8.76961V6.59961H14.1899C13.7799 6.59961 13.4399 6.25961 13.4399 5.84961C13.4399 5.43961 13.7799 5.09961 14.1899 5.09961H17.1199C17.5299 5.09961 17.8699 5.43961 17.8699 5.84961V8.77961C17.8699 9.18961 17.5399 9.51961 17.1199 9.51961Z" fill="#7B7E8C"/>
    <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="#7B7E8C"/>
  </svg>
</template>

<script>
export default {
  name: "LevelIcon",
  props: {
    width: {
      type: Number,
      default: 32,
    },
    height: {
      type: Number,
      default: 32,
    },
  },
}
</script>