<template>
    <div class="empty-class">
					<img
						src="/img/other/empty_folder.png"
						alt="empty_class"
						style="width: 173px; height: 143px" />
					<h3>Ups, Belum Ada Jadwal Kelas</h3>
					<p>
						#MulaiLangkahHebat untuk mengawali karir profesionalmu
						melalui program akselerasi digital HiColleagues
					</p>
				</div>
</template>

<script>
export default {
    name: "Empty"
}
</script>
<style scoped>

.empty-class {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 50vh;
}

.empty-class > h3 {
	color: #7b7e8c;
	font-weight: 600;
	font-size: 19px;
	line-height: 22px;
	margin-top: 16px;
}

.empty-class > p {
	max-width: 420px;
	color: #7b7e8c;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	margin-top: 4px;
}
</style>