<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.39 15.67L13.35 12H10.64L6.59998 15.67C5.46998 16.69 5.09998 18.26 5.64998 19.68C6.19998 21.09 7.53998 22 9.04998 22H14.94C16.46 22 17.79 21.09 18.34 19.68C18.89 18.26 18.52 16.69 17.39 15.67ZM13.82 18.14H10.18C9.79998 18.14 9.49998 17.83 9.49998 17.46C9.49998 17.09 9.80998 16.78 10.18 16.78H13.82C14.2 16.78 14.5 17.09 14.5 17.46C14.5 17.83 14.19 18.14 13.82 18.14Z" fill="#7B7E8C"/>
    <path d="M18.3502 4.32C17.8002 2.91 16.4602 2 14.9502 2H9.05016C7.54016 2 6.20016 2.91 5.65016 4.32C5.11016 5.74 5.48016 7.31 6.61016 8.33L10.6502 12H13.3602L17.4002 8.33C18.5202 7.31 18.8902 5.74 18.3502 4.32ZM13.8202 7.23H10.1802C9.80016 7.23 9.50016 6.92 9.50016 6.55C9.50016 6.18 9.81016 5.87 10.1802 5.87H13.8202C14.2002 5.87 14.5002 6.18 14.5002 6.55C14.5002 6.92 14.1902 7.23 13.8202 7.23Z" fill="#7B7E8C"/>
  </svg>
</template>

<script>
export default {
  name: 'TimerIcon',
  props: {
    width: {
      type: Number,
      default: 32
    },
    height: {
      type: Number,
      default: 32
    }
  }
}
</script>