<template>
	<div class="container-custom">
		<Loading v-if="isLoading" />
		<h2>Hi, {{ name }}</h2>
		<span>Selamat Datang di LMS HiColleagues</span>
		<div
			class="d-flex"
			style="margin-top: 56px; margin-bottom: 40px; align-items: center">
			<div class="justify-start" style="width: 100%">
				<h4 class="class-schedule-text">Jadwal Kelasmu</h4>
			</div>
			<div class="d-flex justify-end" style="align-items: center">
				<span class="category-text">Kategori</span>
				<div style="width: 240px">
					<v-select
						v-model="category"
						:items="categories"
						item-text="name"
						item-value="value"
						dense
						outlined
						hide-details
						class="ma-2"
						style="
							background-color: white;
							color: #36373f;
							font-family: 'Poppins', sans-serif;
							font-weight: 400;
							font-size: 14px;
							line-height: 20px;
						"></v-select>
				</div>
			</div>
		</div>

		<div class="container-class">
			<div v-if="schedules.length === 0" class="empty-class">
				<img
					src="/img/other/empty_folder.png"
					alt="empty_class"
					style="width: 173px; height: 143px" />
				<h3>Ups, Belum Ada Jadwal Kelas</h3>
				<p>
					#MulaiLangkahHebat untuk mengawali karir profesionalmu
					melalui program akselerasi digital HiColleagues
				</p>
			</div>
			<div v-else class="card-container">
				<div v-for="item in schedules" :key="item.class_id">
					<CardClassEvent :data="item" :isTester="isTester" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import getAuthentification from '@/utils/badrequest.js';
import VueJwtDecode from 'vue-jwt-decode';
import CardClassEvent from '../../others/CardClassEvent.vue';
import eventsDummy from '../../../assets/datadummy/events.json';

import Loading from '../../others/Loading.vue';

export default {
	data() {
		return {
			categories: [
				{ name: 'Semua', value: 0 },
				{ name: 'Public Training', value: 1 },
				{ name: 'Mini Bootcamp', value: 2 },
				{ name: 'Corporate Training', value: 3 },
				{ name: 'Kartu Prakerja', value: 4 },
				{ name: 'Bootcamp', value: 5 },
			],
			isTester:
				this.$decode_token['email'].search('tester') < 0 ? false : true,
			schedules: [],
			schedules_list: [],
			name: '',
			category: 0,
			isLoading: false,
		};
	},
	components: {
		Loading,
		CardClassEvent,
	},
	watch: {
		category(v) {
			if (!v) this.schedules = this.schedules_list;
			else
				this.schedules = this.schedules_list.filter(
					(item) => item.category === v,
				);
		},
	},
	beforeMount() {
		if (this.$route.params.token != null) {
			localStorage.setItem('user', this.$route.params.token);
			this.$forceUpdate();
			let decoded = VueJwtDecode.decode(this.$route.params.token);
			this.$decode_token = decoded;
			window.location.href = '/home';
			// this.loginDashboard(this.$route.params.email, this.$route.params.token, this.$route.params.role)
		} else {
			getAuthentification();
		}
		var data = JSON.parse(localStorage.getItem('account'));
		if (data != null) {
			this.name = data['full_name'];
		} else {
			this.name = this.$decode_token['name'];
		}
	},
	methods: {
		async getClass() {
			this.isLoading = true;

			// this.schedules = [];
			this.schedules = eventsDummy;

			// this.schedules_list = eventsDummy;
			this.schedules_list = eventsDummy;

			this.isLoading = false; // delete this line later

			// await this.$http
			//   .get(
			//     "/v1/user-schedule?user_id=" +
			//       this.$decode_token["id"] +
			//       "&status=true"
			//   )
			//   .then(async (response) => {
			//     for (let i = 0; i < response.data.data.schedules.length; i++) {
			//       const item = response.data.data.schedules[i];
			//       this.schedules.push(item);
			//       this.schedules_list.push(item);
			//     }
			//     this.isLoading = false;
			//   })
			//   .catch(() => {
			//     this.isLoading = false;
			//   });
		},
		checkingParam(param) {
			if (!param) return;
			if (param === 'a') {
				// authentication success
				if (this.$utils.getSession(this.$utils.getClass()) == 999) {
					this.$router.push(
						`/class/${this.$utils.getClass()}/session`,
					);
				} else {
					this.$router.push(
						`/class/${this.$utils.getClass()}/detail/${this.$utils.getSession(
							this.$utils.getClass(),
						)}`,
					);
				}
			} else if (param === 'b') {
				// authentication error
				this.$router.push(
					`/class/${this.$utils.getClass()}/verification/${this.$utils.getSession(
						this.$utils.getClass(),
					)}`,
				);
			} else {
				// authentication cancel
				this.$utils.removeSessionCodePrakerja();
			}
		},
	},
	mounted() {
		this.getClass();
		this.checkingParam(this.$route.query.feedback);
	},
};
</script>

<style scoped>
.container-custom {
	background-color: #f5f6f9;
}

.container-custom > h2 {
	color: #36373f;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 24px;
	line-height: 36px;
}

.container-custom > span {
	margin-top: 4px;
	color: #575966;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.class-schedule-text {
	color: #36373f;
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 30px;
}

.category-text {
	color: #36373f;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.container-class {
	margin-top: 32px;
}

.empty-class {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 50vh;
}

.empty-class > h3 {
	color: #7b7e8c;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	margin-top: 16px;
}

.empty-class > p {
	max-width: 420px;
	color: #7b7e8c;
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
	text-align: center;
	margin-top: 4px;
}

.card-container {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
</style>
