<template>
	<div class="container-custom-body">
		<Loading v-if="isLoading" />
		<div class="container-class">
			<div class="d-flex mb-5" style="align-items: center">
				<div class="justify-start" style="width: 100%">
					<h4 class="class-schedule-text">Riwayat Kelas</h4>
				</div>
				<div class="d-flex justify-end" style="align-items: center">
					<span class="category-text">Kategori</span>
					<div style="width: 250px">
						<v-select v-model="category" :items="categories" item-text="name" item-value="value" dense
							outlined hide-details class="ma-2"></v-select>
					</div>
				</div>
			</div>
			<div v-for="item in schedules" :key="item.class_id">
				<CardClass :data="item" :isMyClassPage="true"  :isTamat="true" />
			</div>
			<Empty v-if="!schedules.length" />
		</div>
	</div>
</template>

<script>
import Loading from '../../others/Loading.vue';
import CardClass from '../../others/CardClass.vue';
import Empty from '../../Empty.vue';
export default {
	components: {
		CardClass,
		Loading,
		Empty
	},
	data() {
		return {
			categories: [
				{ name: 'Semua', value: 0 },
				{ name: 'Bootcamp', value: 5 },
				{ name: 'Prakerja', value: 4 },
				{ name: 'Public Training', value: 1 },
			],
			
			schedules: [],
			schedules_list: [],
			category: 0,
			isLoading: false,
		};
	},
	watch: {
		category(v) {
			if (!v) this.schedules = this.schedules_list;
			else
				this.schedules = this.schedules_list.filter(
					(item) => item.category === v,
				);
		},
	},
	methods: {
		async getClass() {
			this.isLoading = true;
			this.schedules = [];
			this.schedules_list = [];
			await this.$http
				.get(
					'/v1/user-schedule?user_id=' +
					this.$decode_token['id'] +
					'&status=false',
				)
				.then(async (response) => {
					for (
						let i = 0;
						i < response.data.data.schedules.length;
						i++
					) {
						const item = response.data.data.schedules[i];
						this.schedules.push(item);
						this.schedules_list.push(item);
					}
					this.isLoading = false;
				})
				.catch(() => {
					this.isLoading = false;
				});
		},
	},
	mounted() {
		this.getClass();
	},
};
</script>

<style scoped>
.name-circle {
	font-weight: 600;
	font-size: 18px;
	line-height: 25px;
	text-align: center;
	color: #ffffff;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: linear-gradient(111.75deg, #179eff -0.58%, #162ca2 100.82%);
}

.container-chat {
	margin-top: 10px;
	padding: 5px 19px;
	border-radius: 18px;
	position: relative;
	z-index: 10px;
	border: 1px solid #d3d6e1;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.container-chat .content {
	display: flex;
	gap: 8px;
	align-items: center !important;
}

.container-history {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.button-history {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	cursor: pointer;
	color: #36373f;
	padding: 5px 10px;
	font-size: 15px;
}

.button-history:hover {
	color:#162ca2 !important;
}

.button-history p {
	margin: 0;
	font-family: 'Poppins', sans-serif;
}

.class-schedule-text {
	color: #36373f;
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 30px;
}

.category-text {
	color: #36373f;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.container-class {
	background-color: white;
	padding: 40px 32px;
	border-radius: 24px;
	margin-top: 10px;
}
</style>
