<template>
  <v-app>
      <div :class="`${!isMobile ? 'd-flex': ''}`">
        <Navbar />
        <v-main>
          <router-view />
        </v-main>
      </div>
  </v-app>
</template>

<script>
import Navbar from "@/views/Navbar/Navbar.vue";
export default {
  name: "App",
  components: {
    Navbar
  },
  data() {
    return {
      full_name: "",
      isBadRequest: false,
      isMobile: false,
    };
  },
  methods: {
    handleView() {
      this.isMobile = screen.width <= 990;
    },
  },
  mounted() {
    this.handleView();
  },
  beforeMount() {
    if (!sessionStorage.getItem("pra-key")) {
      sessionStorage.setItem("pra-key", JSON.stringify([]))
    }
    if (!localStorage.getItem("pra-key")) {
      localStorage.setItem("pra-key", JSON.stringify([]))
    }
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

#font-poppins {
  font-family: "Poppins", sans-serif !important;
}

.template-mo-1 {
  background: #F5F6F9;
  padding: 10px;
}

.container-custom {
  position: static;
  margin-left: 40px;
  padding: 70px 100px;
  background: #F5F6F9;
  width: 100%;
  min-height: 100vh;
}

.container-custom-body {
  min-height: 100vh;
  position: static;
  margin-left: 150px;
  padding: 70px 200px;
  background: #F5F6F9;
  width: 100%;
}

.card-schedule {
  background: #ffffff;
  border-radius: 30px;
  padding: 20px 30px;
  margin-bottom: 20px;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.title {
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: #36373f;
}

.long-line {
  width: 100%;
  height: 1px;
  background: #d3d6e1;
  margin: 10px 0px;
}

.box-info {
  text-align: center;
  margin: 0 30px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-info img {
  display: block;
}

.box-img {
  margin-right: 10px;
}
.text-red {
  font-weight: 500;
  font-size: 15px;
  color: #B80F0A;
}

.button-blue {
  width: 180px;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
  font-weight: 600;
  background: #162ca2;
  border-radius: 15px;
  color: white;
  padding: 5px 0px;
  cursor: pointer;
}
.notification-info {
    padding: 20px 30px;
    background-color: #F1F6FF;
    display: flex;
    align-items: flex-start;
    gap: 30px;
}



.button-light-blue {
  width: 135px;
  background: #f5f6f9;
  border-radius: 24px;
  color: #162ca2;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
  padding: 5px 0px;
}

.button-grey {
  width: 135px;
  background: #ffffff;
  border: 1px solid #d3d6e1;
  border-radius: 12px;
  text-align: center;
  padding: 5px 0px;
  cursor: pointer;
}

.v-breadcrumbs a {
  color: #162ca2 !important;
}

.v-breadcrumbs__item--disabled {
  pointer-events: none;
  font-weight: bold;
  color: #36373f !important;
}

.container-radius {
  margin-top: 35px;
  padding: 40px;
  background: #ffffff;
  border-radius: 24px;
}

.bg-ghost-white {
  background: #f7f9fd;
  border-radius: 24px;
  padding: 28px 50px;
}

.btn-out-grey {
  padding: 7px 20px;
  background: #ffffff;
  justify-content: center;
  align-content: center;
  border: 1px solid #d3d6e1;
  border-radius: 16px;
  cursor: pointer;
}

.btn-radius {
  height: 56px !important;
  margin-left: auto;
  border-radius: 16px !important;
  font-weight: 600;
  cursor: pointer;
}

.vertical-line {
  width: 2px;
  height: 100%;
  background: #d3d6e1;
}

.btn-stoke-blue {
  display: flex;
  border: 1px solid #162ca2;
  border-radius: 15px !important;
  padding: 15px 80px !important;
  height: 56px !important;
  color: #162ca2 !important;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.gray-button {
  color: #575966 !important;
  border-color: #7b7e8c;
}
.font-bold {
  font-weight: bold;
}

.text-line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  text-overflow: ellipsis;
  overflow: hidden;
  visibility: visible;
}

.text-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  text-overflow: ellipsis;
  overflow: hidden;
  visibility: visible;
}

.text-line-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  text-overflow: ellipsis;
  overflow: hidden;
  visibility: visible;
}

.text-line-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
  text-overflow: ellipsis;
  overflow: hidden;
  visibility: visible;
}

.text-line-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;  
  text-overflow: ellipsis;
  overflow: hidden;
  visibility: visible;
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}

.text-left {
  text-align: left !important;
}

.category-container {
  padding: 25px;
  position: fixed;
  z-index: 50;
  bottom: 0;
  border-radius: 30px 30px 0 0 ;
  width: 100%;
  background-color: white;
}
.button-filter {
  position: fixed;
  bottom: 10px;
  width: 100%;
  justify-content: center;
  display: flex;
  left: 0;
}
/* mobile view */
.info-class {
  font-size: 12px;
  margin-top: 5px;
  gap: 8px;
}
.info-class-ctn {
  display: flex;
  justify-content: flex-start;
  gap: 1px;
  gap: 10px;
  margin-top: 8px;
  align-items: center;
}

.info-class-ctn span {
  font-size: 13px;
  text-align: center;
  display: block;
}

.info-class-ctn img {
  display: block;
  height: 27px;
  width: 27px;
}

@media only screen and (max-width: 900px) {
  .hamburger-menu {
    padding: 20px;
    background: white;
    width: 100%;
    height: 100vh;
    z-index: 1;
    position: relative;
  }
  .text-red {
    font-size: 13px;
  }
  .container-body-mobile {
    background: #F5F6F9;
    min-height: 100vh;
    padding: 80px 19px;
  }

  .container-radius {
    margin-top: 20px;
    text-align: justify;
    padding: 20px;
    background: #ffffff;
    border-radius: 24px;
  }
  .button-light-blue {
    width: 100px;
    background: #f5f6f9;
    border-radius: 24px;
    color: #162ca2;
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px;
    padding: 0px 10px;
    font-size: 11px;
  }
  .notification-info {
    padding: 10px 20px;
    gap: 10px;
}

  .card-schedule {
    background: #ffffff;
    border-radius: 24px;
    padding: 15px;
    margin-bottom: 10px;
    align-items: center;
    box-shadow: 2px 2px 10px 3px rgba(13, 28, 113, 0.1);
  }
  .bg-ghost-white {
    background: #f7f9fd;
    border-radius: 24px;
    padding: 15px;
  }

  .btn-radius {
    height: 40px !important;
    margin-left: auto;
    border-radius: 16px !important;
    font-weight: 600;
    cursor: pointer;
  }

  .btn-stoke-blue {
    display: flex;
    border: 1px solid #162ca2;
    border-radius: 15px !important;
    padding: 15px 80px !important;
    height: 40px !important;
    color: #162ca2 !important;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
}
</style>
