<template>
	<div class="container-custom-body">
		<Loading v-if="isLoading" />
		<div class="container-history">
			<button
				class="button-history"
				@click="() => this.$router.push('/history-class/event')">
				<ClockIcon :width="24" :height="24" />
				<p>Riwayat Kelas</p>
			</button>
		</div>
		<div class="container-class">
			<div class="d-flex" style="align-items: center">
				<div class="justify-start" style="width: 100%">
					<h4 class="class-schedule-text">Kelas Aktif</h4>
				</div>
				<div class="d-flex justify-end" style="align-items: center">
					<span class="category-text">Kategori</span>
					<div style="width: 240px">
						<v-select
							v-model="category"
							:items="categories"
							item-text="name"
							item-value="value"
							dense
							outlined
							hide-details
							class="ma-2"
							style="
								background-color: white;
								color: #36373f;
								font-family: 'Poppins', sans-serif;
								font-weight: 400;
								font-size: 14px;
								line-height: 20px;
							"></v-select>
					</div>
				</div>
			</div>
			<div class="container-card">
				<div v-if="schedules.length === 0" class="empty-class">
					<img
						src="/img/other/empty_folder.png"
						alt="empty_class"
						style="width: 173px; height: 143px" />
					<h3>Ups, Belum Ada Jadwal Kelas</h3>
					<p>
						#MulaiLangkahHebat untuk mengawali karir profesionalmu
						melalui program akselerasi digital HiColleagues
					</p>
				</div>
				<div v-else v-for="item in schedules" :key="item.class_id">
					<CardClassEvent
						:data="item"
						:isMyClassPage="true"
						:isTamat="item.is_tamat" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loading from '../../others/Loading.vue';
import CardClassEvent from '../../others/CardClassEvent.vue';
import ClockIcon from '@/assets/icons/Clock.vue';
import eventsDummy from '../../../assets/datadummy/events.json';

export default {
	components: {
		CardClassEvent,
		ClockIcon,
		Loading,
	},
	data() {
		return {
			categories: [
				{ name: 'Semua', value: 0 },
				{ name: 'Public Training', value: 1 },
				{ name: 'Mini Bootcamp', value: 2 },
				{ name: 'Corporate Training', value: 3 },
				{ name: 'Kartu Prakerja', value: 4 },
				{ name: 'Bootcamp', value: 5 },
			],
			isTester:
				this.$decode_token['email'].search('tester') < 0 ? false : true,
			schedules: [],
			schedules_list: [],
			category: 0,
			limitText: 240,
			isLoading: false,
			classOnReview: {},
		};
	},
	watch: {
		category(v) {
			if (!v) this.schedules = this.schedules_list;
			else
				this.schedules = this.schedules_list.filter(
					(item) => item.category === v,
				);
		},
	},
	methods: {
		async getClass() {
			this.isLoading = true;

			// this.schedules = [];
			this.schedules = eventsDummy;

			// this.schedules_list = [];
			this.schedules_list = eventsDummy;

			this.isLoading = false; // delete this line later

			// await this.$http
			//   .get(
			//     "/v1/user-schedule?user_id=" +
			//       this.$decode_token["id"] +
			//       "&status=true"
			//   )
			//   .then(async (response) => {
			//     for (let i = 0; i < response.data.data.schedules.length; i++) {
			//       const item = response.data.data.schedules[i];
			//       this.schedules.push(item);
			//       this.schedules_list.push(item );
			//     }
			//     this.isLoading = false
			//   })
			//   .catch(() => {
			//     this.isLoading = false
			//   });
		},
	},
	mounted() {
		this.getClass();
	},
};
</script>

<style scoped>
.container-custom-body {
	background-color: #f5f6f9;
}

.container-history {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.button-history {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	cursor: pointer;
	padding: 5px 10px;
}

.button-history:hover {
	background-color: #d3d6e1;
	border-radius: 10px;
}

.button-history p {
	margin: 0;
	color: #36373f;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.class-schedule-text {
	color: #36373f;
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 30px;
}

.category-text {
	color: #36373f;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.container-class {
	background-color: white;
	padding: 40px 32px;
	border-radius: 24px;
	margin-top: 24px;
}

.container-card {
	margin-top: 32px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.empty-class {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 50vh;
}

.empty-class > h3 {
	color: #7b7e8c;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	margin-top: 16px;
}

.empty-class > p {
	max-width: 420px;
	color: #7b7e8c;
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
	text-align: center;
	margin-top: 4px;
}

.name-circle {
	font-weight: 600;
	font-size: 18px;
	line-height: 25px;
	text-align: center;
	color: #ffffff;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: linear-gradient(111.75deg, #179eff -0.58%, #162ca2 100.82%);
}
.container-chat {
	margin-top: 10px;
	padding: 5px 19px;
	border-radius: 18px;
	position: relative;
	z-index: 10px;
	border: 1px solid #d3d6e1;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.container-chat .content {
	display: flex;
	gap: 8px;
	align-items: center !important;
}
</style>
