<template>
    <div class="container-custom" style="margin-left: 0px;">
        <Popup :message="errorMessage" isError v-if="isShowPopup"
            @close="() => { isShowPopup = !isShowPopup }" />
        <Modal v-if="isModal" @close="() => (isModal = false)" width="60%">
            <img :src="detailImg" alt="Detail Gambar" style="width: 100%; height: 98%;">
        </Modal>
        <div class="navigation" hidden>
            <span @click="goToPage('/active-class')">Kelas Aktif</span>
            <Arrow :width="24" :height="24" direct="right" strokeColor="#7B7E8C" class="icon" />
            <span @click="goToPage(`/class/${question_id}${class_schedule.category == 4 ? `/session` : ''}`)">Detail Kelas</span>
            <Arrow :width="24" :height="24" direct="right" strokeColor="#7B7E8C" class="icon" />
            <span @click="goToPage(`/class/${question_id}/detail${class_schedule.category == 4 ? '/'+ queryParams.g : ''}`)">
                {{ this.$helpers.maxStringLength(class_schedule.name, 20) }}
            </span>
            <div style="display: flex; align-items: center; gap: 15px;" v-if="queryParams.g">
                <Arrow :width="24" :height="24" direct="right" strokeColor="#7B7E8C" class="icon" />
                <span>Sesi {{ queryParams.g }}</span>
            </div>
            <div style="display: flex; align-items: center;gap: 15px;" v-if="queryParams.c">
                <Arrow :width="24" :height="24" direct="right" strokeColor="#7B7E8C" class="icon" />
                <span>{{ queryParams.c }}</span>
            </div>
        </div>
        <div class="header">
            <h1>{{ queryParams.c }}</h1>
            <div v-if="is_prakerja" class="prakerja-icon">
                <img src="/img/icons/HiColl_Prakerje.png" alt="Prakerja">
            </div>
        </div>
        <LineDivider height="1px" />
        <div class="questions-list">
            <div class="question" v-for="(n, idx1)  in question_sets" :key="n.number">
                <div class="card">
                    <img :src="n.attached_file" :alt="`Resource ` + idx1 " v-if="n.attached_file_type === 'image'" class="question-img" title="click to see detail" @click="showDetailImage(n.attached_file)">
                    <span class="title">{{ n.question }}</span>
                    <v-radio-group>
                        <v-radio v-for="(i, idx2) in n.choices" :key="i.option" :label="`${i.description}`" 
                            :value="i.option" @click="() => {
                                chooseOption(n.question, i.option)
                                updateSelectedChoice(idx1, idx2)
                            }" :class="i.selected ? 'answer-selected' : 'answer'"></v-radio>
                    </v-radio-group>
                </div>
            </div>
            <br>
            <div class="container-submit">
                <span>
                    Apakah HiColers yakin mengumpulkan jawaban ujian ini?
                </span>
                <v-btn depressed color="#162CA2" class="btn-radius btn-submit" :disabled="!isDone" @click="submitAnswer()">
                    <span class="text-capitalize font-weight-bold white--text">SUBMIT</span>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import Arrow from "@/assets/icons/Arrow.vue";
import LineDivider from "../../others/LineDivider.vue";
import Popup from "@/components/others/Popup.vue";
import Modal from '@/components/Modal.vue';

export default {
    components: {
        Arrow,
        LineDivider,
        Popup,
        Modal
    },
    data() {
        return {
            question_id: this.$route.params.id,
            question_sets: [],
            tempAnswer: [],
            category: "",
            isDone: false,
            group: 0,
            class_schedule: {},
            is_prakerja: false,
            queryParams: {
                c: "",
                g: 0
            },
            isModal: false,
            detailImg: "",
            isShowPopup: false,
            errorMessage: "",
        }
    },
    methods: {
        async getQuestion() {
            await this.$http
                .get("/v1/question-set?class_id=" + this.$route.params.id + "&group=" + this.$route.query.g + "&category_question_set=" + this.category)
                .then((response) => {
                    var tempNumber = 1
                    let { questions_sets } = response.data.data;
                    questions_sets = questions_sets.map((question) => {
                        let { choices } = question
                        choices = choices.map((choice) => {
                            return {
                                ...choice,
                                selected: false
                            }
                        })
                        return {
                            ...question,
                            choices: choices
                        }
                    })

                    questions_sets.forEach((element) => {
                        this.question_sets.push({
                            grouping: element.grouping,
                            class_id: element.class_id,
                            category_question_set: element.category_question_set,
                            question: element.question,
                            question_type: element.question_type,
                            choices: element.choices,
                            correct_answer: element.correct_answer,
                            point: element.point,
                            attached_file: element.attached_file,
                            attached_file_type: element.attached_file_type,
                            number: tempNumber++
                        });
                    });
                })
                .catch((err) => console.error(err.response));
        },
        chooseOption(question, answer) {
            this.tempAnswer.push({
                question: question,
                answer: answer
            })
            if (this.tempAnswer.length >= this.question_sets.length) {
                this.isDone = true
            }
        },
        showDetailImage(img) {
            this.isModal = true
            this.detailImg = img
        },
        async submitAnswer() {
            await this.$http
                .post("/v1/user-assesment", {
                    class_id: parseInt(this.$route.params.id),
                    user_id: this.$decode_token["id"],
                    answers: this.tempAnswer,
                    category_question_set: this.$route.query.c,
                    group: parseInt(this.$route.query.g)
                })
                .then(() => {
                    if (this.$route.query.c.toLowerCase() !== "post test") {
                        sessionStorage.setItem("bck_to", `${this.$route.params.id}-${this.$route.query.g}`)
                    }
                    
                    window.location.href = 'finish';
                })
                .catch((err) => {
                    console.error(err.response)
                    this.isShowPopup = true
                    this.errorMessage = "Ups, submit jawaban ujian gagal. Coba lagi!"
                })
        },
        async getClass() {
            try {
                const res = await this.$http.get("/v1/user-schedule/detail?user_id=" + this.$decode_token["id"] + "&class_id=" + this.$route.params.id)

                this.class_schedule = res.data.data.schedule
                this.is_prakerja = res.data.data.schedule.category == 4 ? true : false
            } catch (error) {
                console.error(error.response)

            }
        },
        getQueryParams() {
            const query = this.$route.query;
            this.queryParams = query;
        },
        goToPage(page) {
            window.location.href = page;
        },
        updateSelectedChoice(questionIdx, selectedIdx) {
            this.question_sets[questionIdx].choices.forEach((choice, idx) => {
                if (idx == selectedIdx) {
                    choice.selected = true
                } else {
                    choice.selected = false
                }
            })
        }
    },
    mounted() {
        if (this.$route.query.c == "Quiz") {
            this.category = "quiz"
        } else {
            this.category = "pre post test"
        }

        this.getQuestion();
        this.getClass();
        this.getQueryParams();
    }
}
</script>

<style scoped>
.container-submit {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 24px;
}
.navigation {
    margin-top: -30px;
    margin-left: -50px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
}

.navigation span {
    color: #162CA2;
    font-weight: bold;
}

.navigation span:hover {
    cursor: pointer;
}

.navigation span:nth-last-child(1) {
    /* Atur gaya CSS yang berbeda di sini */
    /* Contoh: */
    color: #575966;
}

.navigation span:nth-last-child(1):hover {
    cursor: default;
}

.header {
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
    padding: 30px 0;
    border-bottom: 1px solid #7B7E8C;
}
.question-img {
    object-fit: contain;
    width: 100%;
    height: 350px;
    margin-bottom: 15px;
    cursor: pointer;
}
.prakerja-icon {
    position: absolute;
    right: 0;
}

.prakerja-icon img {
    height: 48px;
}


.questions-list {
	margin-top: 32px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.question {
	display: flex;
	align-items: flex-start;
}

.num {
	background-color: #ffde59;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 35px;
	font-weight: 500;
	height: 45px;
	border-radius: 10px 0 0 10px;
}

.card {
	background-color: #ffffff;
	width: 100%;
	border-radius: 16px;
	padding: 24px 40px;
}
.card .title {
    font-size: medium !important;
    line-height: 27px;
    color: #36373F;
    font-weight: bold;
    font-family: 'Poppins' !important;
}


.answer {
    background-color: transparent;
    border-radius: 15px;
    padding: 8px;
    font-weight: bold !important;
}

.answer-selected {
    background-color: #F1F6FF;
    border-radius: 15px;
    padding: 8px;
    font-weight: bold !important;
}

.btn-submit {
    margin: 0;
    font-size: 22px;
    max-width: 400px;
    border-radius: 18px !important;
    width: 100%;
}
</style>