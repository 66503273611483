<template>
	<div class="container-custom">
		<div class="card">
			<img src="/img/icons/Success Notification.png" alt="Success" />
			<h2>Ujian Selesai</h2>
			<p>
				Yeay, HiColers telah selesai mengerjakan ujian. Cari tahu
				seluruh skor yang HiColers dapatkan?
			</p>
			<div class="btn-group">
				<button class="btn-outline" @click="goToPage('HISTORY')">
					KEMBALI KE KELAS
				</button>
				<button class="btn" @click="goToPage('ASSESSMENT')">
					LIHAT RAPOR
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		goToPage(page) {
			if (page === 'ASSESSMENT') {
				window.location.href = `/assesment`;
			} else {
				let back_to = sessionStorage.getItem('bck_to');
				if (!back_to) {
					window.location.href = '/active-class';
					return;
				}
				back_to = back_to.split('-');
				window.location.href = `/class/${back_to[0]}/detail/${back_to[1]}?done=true`;
				sessionStorage.removeItem('bck_to');
			}
		},
	},
};
</script>

<style scoped>
.container-custom {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
	background-color: #f1f6ff;
}

.card {
	background-color: #fff;
	border-radius: 16px;
	max-width: 490px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 32px 48px;
	flex-direction: column;
}

.card img {
	width: 288px;
	height: 198px;
	margin-bottom: 30px;
}

.card h2 {
	color: #36373f;
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: 30px;
	margin-bottom: 8px;
}

.card p {
	text-align: center;
	color: #575966;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 32px;
}

.btn-group {
	display: flex;
	gap: 12px;
	justify-content: center;
	width: 100%;
}

.btn {
	background-color: #162ca2;
	border-radius: 12px;
	padding: 14px 45px;
	color: white;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 1px;
}

.btn-outline {
	border: 1px solid #162ca2;
	border-radius: 12px;
	padding: 14px 30px;
	color: #162ca2;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 1px;
}
</style>
